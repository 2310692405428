import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Muddy Sniveling Mallard</title>
        <meta property="og:title" content="Muddy Sniveling Mallard" />
      </Helmet>
      <div className="home-container1">
        <span className="home-text">Living in harmony.</span>
      </div>
      <div className="home-container2">
        <span className="home-text1">Organisations in Planning</span>
        <div className="home-container3">
          <img
            alt="image"
            src="/playground_assets/xdfghjkjhgf.svg"
            className="home-image"
          />
          <img
            alt="image"
            src="/playground_assets/cgvhbjnkmn.svg"
            className="home-image1"
          />
          <img
            alt="image"
            src="/playground_assets/fccgvhbjnkm.svg"
            className="home-image2"
          />
          <img
            alt="image"
            src="/playground_assets/cfcghjk.svg"
            className="home-image3"
          />
          <img
            alt="image"
            src="/playground_assets/ghjkm.svg"
            className="home-image4"
          />
          <img
            alt="image"
            src="/playground_assets/gfhjkl.svg"
            className="home-image5"
          />
        </div>
      </div>
      <div className="home-container4">
        <span className="home-text2">
          Additional organisations planed, remaining possible.
        </span>
      </div>
      <div className="home-container5">
        <a
          href="mailto:livinginharmoney@protonmail.com?subject=Hello, I am interested in joining forces with Living in Harmony organization."
          className="home-link"
        >
          livinginharmoney@protonmail.com
        </a>
        <span className="home-text3">or</span>
        <a
          href="https://matrix.to/#/@.gdwill88:matrix.org"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link1"
        >
          https://matrix.to/#/@.gdwill88:matrix.org
        </a>
      </div>
    </div>
  )
}

export default Home
